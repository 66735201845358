import Layout from "./layout";
import { ManageWordClouds } from ":src/modules/wordcloud";
import { ManageOpenQuestions } from ":src/modules/open-questions";
import {ManagePoll} from ":src/modules/poll/";
export default function AdminRouter() {
  return (
    <Layout>
      <ManageWordClouds />
      <ManageOpenQuestions />
    <ManagePoll/>
    </Layout>
  );
}
